import { RemixBrowser, useLocation, useMatches } from "@remix-run/react"
import * as Sentry from "@sentry/remix"
import { startTransition, StrictMode, useEffect } from "react"
import { hydrateRoot } from "react-dom/client"

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    replaysOnErrorSampleRate: 1,
    replaysSessionSampleRate: 1,
  })

  import("@sentry/remix").then((lazyLoadedSentry) => {
    Sentry.addIntegration(
      lazyLoadedSentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      })
    )

    Sentry.addIntegration(lazyLoadedSentry.replayIntegration())
  })
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  )
})
